import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function navbar_toggle() {
  const menuButton = document.querySelector(".nav-menu");
  const nav = document.querySelector(".navbar-collapse");
  const footerItems = document.querySelectorAll(".nav-footer > div");
  // const navMenu = document.querySelector(".navbar-nav");
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let interval;

  menuButton.addEventListener("click", () => {
    if (menuButton.dataset.value === "Menu") {
      menuButton.dataset.value = "Close";
      nav.animate(
        [
          {
            transform: "translateY(-100%)",
          },
          {
            transform: "translateY(0%)",
          },
        ],
        { duration: 750, easing: "ease-in", fill: "forwards" }
      );
      footerItems.forEach((item) =>
        item.animate(
          [
            {
              transform: "translateY(-100%)",
            },
            {
              transform: "translateY(0%)",
              opacity: 1,
            },
          ],
          { duration: 500, delay: 750, easing: "ease-in", fill: "forwards" }
        )
      );
      gsap.to(".nav-image-wrapper", {
        clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
        duration: 1,
        delay: 0.75,
        ease: "power1.out",
      });
    } else {
      menuButton.dataset.value = "Menu";
      footerItems.forEach((item) =>
        item.animate(
          [
            {
              transform: "translateY(0%)",
            },
            {
              transform: "translateY(-100%)",
              opacity: 0,
            },
          ],
          { duration: 500, easing: "ease-in", fill: "forwards" }
        )
      );
      gsap.to(".nav-image-wrapper", {
        clipPath: " polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
        duration: 0.5,
        ease: "power1.out",
      });
      nav.animate(
        [
          {
            transform: "translateY(0%)",
          },
          {
            transform: "translateY(-100%)",
          },
        ],
        { delay: 500, duration: 750, easing: "ease-out", fill: "forwards" }
      );
    }

    let iteration = 0;

    clearInterval(interval);

    interval = setInterval(() => {
      menuButton.innerText = menuButton.dataset.value
        .split("")
        .map((_, index) => {
          if (index < iteration) {
            return menuButton.dataset.value[index];
          }
          return letters[Math.floor(Math.random() * 26)];
        })
        .join("");

      if (iteration >= menuButton.dataset.value.length) {
        clearInterval(interval);
      }

      iteration += 1 / 2;
    }, 30);
  });
}

function navlink_click() {
  const elements = document.querySelectorAll(".nav-link");
  const menuButton = document.querySelector(".nav-menu");
  const nav = document.querySelector(".navbar-collapse");
  const footerItems = document.querySelectorAll(".nav-footer > div");

  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let interval;

  elements.forEach((link) => {
    link.addEventListener("click", () => {
      menuButton.dataset.value = "Menu";
      footerItems.forEach((item) =>
        item.animate(
          [
            {
              transform: "translateY(0%)",
            },
            {
              transform: "translateY(-100%)",
              opacity: 0,
            },
          ],
          { duration: 500, easing: "ease-in", fill: "forwards" }
        )
      );
      gsap.to(".nav-image-wrapper", {
        clipPath: " polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)",
        duration: 0.5,
        ease: "power1.out",
      });
      nav.animate(
        [
          {
            transform: "translateY(0%)",
          },
          {
            transform: "translateY(-100%)",
          },
        ],
        { delay: 500, duration: 750, easing: "ease-out", fill: "forwards" }
      );

      let iteration = 0;

      clearInterval(interval);

      interval = setInterval(() => {
        menuButton.innerText = menuButton.dataset.value
          .split("")
          .map((_, index) => {
            if (index < iteration) {
              return menuButton.dataset.value[index];
            }
            return letters[Math.floor(Math.random() * 26)];
          })
          .join("");

        if (iteration >= menuButton.dataset.value.length) {
          clearInterval(interval);
        }

        iteration += 1 / 2;
      }, 30);
    });
  });
}

function navlink_hover() {
  const elements = document.querySelectorAll(".nav-link");

  elements.forEach((el) => {
    const innerText = el.innerText;
    el.innerHTML = "";

    const textContainer = document.createElement("div");
    textContainer.classList.add("block");

    for (let letter of innerText) {
      const span = document.createElement("span");
      span.innerText = letter.trim() === "" ? "\xa0" : letter;
      span.classList.add("letter");
      textContainer.appendChild(span);
    }

    el.appendChild(textContainer);
    el.appendChild(textContainer.cloneNode(true));
  });

  elements.forEach((el) => {
    el.addEventListener("mouseover", () => el.classList.remove("play"));
  });
}

function hero_section() {
  gsap.to(".hero-gallery .hero-img", {
    clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)",
    duration: 2,
    ease: "power4.in",
    stagger: 0.2,
    delay: 0.3,
  });

  gsap.to(".hero-gallery", {
    scale: 1.25,
    duration: 2,
    ease: "power2.Out ",
    delay: 2,
  });

  gsap.to(".nav-logo", {
    opacity: 1,
    duration: 0.5,
    ease: "linear",
    delay: 3.3,
  });

  gsap.to(".nav-menu", {
    clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
    duration: 0.5,
    ease: "linear",
    delay: 3.3,
  });

  gsap.to(".hero-logo", {
    clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
    duration: 1,
    ease: "power4.inOut",
    delay: 3.3,
  });
}

function about_section() {
  const aboutTitle = document.querySelector(".about-title");
  const text = aboutTitle.textContent;
  aboutTitle.innerHTML = "";
  text.split("").forEach((char) => {
    const div = document.createElement("div");
    div.innerText = char;
    aboutTitle.appendChild(div);
  });

  gsap.from(".about-caption", {
    display: "none",
    opacity: 0,
    duration: 1,
    ease: "power1.out",
    scrollTrigger: {
      trigger: ".about-section",
      start: "top center",
    },
  });

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".about-section",
      start: "top 0px",
      end: "bottom 0%",
      pin: true,
      pinSpacing: true,
      scrub: 3,
    },
  });
  tl.from(aboutTitle.childNodes, {
    y: "100%",
    duration: 1.5,
    ease: "power1.out",
    stagger: 0.07,
  });
  tl.from(".about-text", {
    opacity: 0,
    y: 50,
    duration: 1,
    ease: "power1.out",
    stagger: 0.5,
  });
}

function brand_section() {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".brand-section",
      start: "top 40%",
      end: "bottom 100%",
      scrub: 1,
    },
  });

  tl.to(".brand-image", {
    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    ease: "power1.out",
  });

  tl.from(".brand-title div", {
    opacity: 0,
    y: 50,
    stagger: 0.3,
    ease: "power1.out",
  });
}

function services_section() {
  const isMobile = window.innerWidth < 600;

  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: ".services-section",
      start: "top 50%",
      end: isMobile ? "bottom 100%" : "top 0%",
      scrub: 1,
    },
  });

  tl.from(".services-section-title", {
    opacity: 0,
    y: 50,
    ease: "power1.out",
  });

  tl.from(".services-item", {
    opacity: 0,
    y: 50,
    stagger: isMobile
      ? 0.5
      : {
          grid: [2, 4],
          from: "start",
          axis: "y",
          ease: "power1.Out",
          amount: 1.5,
        },
    duration: 0.4,
    ease: "power1.out",
  });
}

function objective_section() {
  const isMobile = window.innerWidth < 600;

  gsap.from(".objective-section", {
    opacity: 0,
    duration: 1,
    scrollTrigger: {
      trigger: ".objective-section",
      start: isMobile ? "top 100%" : "top 50%",
      end: isMobile ? "top 50%" : "top 0%",
      scrub: 1,
    },
  });

  let tl;
  if (!isMobile) {
    tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".objective-section",
        start: "top 0%",
        end: "bottom -150%",
        pin: ".objective-section",
        pinSpacing: true,
        scrub: 2,
      },
    });
  } else {
    tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".objective-section",
        start: "top 70%",
        end: "bottom 100%",
        scrub: 1,
      },
    });
  }

  tl.from(".objective-title", {
    y: isMobile ? 30 : 40,
    opacity: 0,
    duration: 0.3,
    ease: "power4.inout",
  });

  for (let i = 1; i <= 4; i++) {
    const cardNumber = document.querySelector(
      `.objective-card:nth-of-type(${i}) .card-number`
    );
    const cardTitle = document.querySelector(
      `.objective-card:nth-of-type(${i}) .card-title`
    );
    const cardDesc = document.querySelector(
      `.objective-card:nth-of-type(${i}) .card-text`
    );

    tl.from(cardNumber, {
      y: "120%",
      duration: 0.4,
      ease: "power1.out",
    });
    tl.from(cardTitle, {
      y: "120%",
      duration: 0.4,
      ease: "power1.out",
    });
    tl.from(cardDesc, {
      y: "120%",
      duration: 0.4,
      ease: "power1.out",
    });

    if (i < 4) {
      const cardDivider = document.querySelector(
        `.card-divider:nth-of-type(${i})`
      );

      tl.to(cardDivider, {
        width: "100%",
        duration: 0.4,
        ease: "power1.out",
      });
    }
  }
}

function logo_section() {
  gsap.from(".logo-section", {
    opacity: 0,
    ease: "power2.out",
    scrollTrigger: {
      trigger: ".testimonial-section",
      start: "top 100%",
      end: "top 75%",
      scrub: 1,
    },
  });
}

function testimonial_section() {
  gsap.to(".testimonial-container", {
    clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)",
    duration: 1,
    ease: "power2.out",
    scrollTrigger: {
      trigger: ".testimonial-section",
      start: "top 50%",
      end: "top 25%",
      scrub: 1,
    },
  });

  gsap.to(".testimonial-item", {
    clipPath: "polygon(100% 0%, 0% 0%, 0% 100%, 100% 100%)",
    duration: 0.3,
    ease: "power4.inout",
    stagger: 6,
    delay: 1,
    repeat: -1,
    repeatDelay: 1,
    yoyo: true,
    yoyoEase: "power4.out",
  });
}

function footer_section() {
  const footer = document.querySelector(".footer-section");
  const footerHeight = footer.getBoundingClientRect().height;

  gsap.to(".footer-section", {
    clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
    duration: 1,
    ease: "power1.out",
    scrollTrigger: {
      trigger: ".footer-section",
      start: "top 100%",
      end: `top ${window.innerHeight - footerHeight}px`,
      scrub: 1,
    },
  });
}

function init() {
  navbar_toggle();
  navlink_click();
  navlink_hover();
  hero_section();
  about_section();
  brand_section();
  services_section();
  objective_section();
  logo_section();
  testimonial_section();
  footer_section();
}

document.addEventListener("DOMContentLoaded", () => init());
